import React, { useState } from 'react';
import './guide.css';

// Import des icônes depuis react-icons
import {
  FaMicrophone,
  FaFileAlt,
  FaGlobe,
  FaClipboardList,
  FaLanguage,
  FaShareAlt
} from 'react-icons/fa';

function GuideUtilisationPage() {
  // État local pour afficher/masquer la liste des langues
  const [showLanguages, setShowLanguages] = useState(false);

  // Fonction de bascule pour le bouton “Voir / Cacher”
  const toggleLanguages = () => {
    setShowLanguages((prev) => !prev);
  };

  return (
    <div className="guide-page">
      {/* En-tête */}
      <header className="guide-header">
        <h1>Comment ça marche&nbsp;?</h1>
        <p>Clarity sauve votre temps. Votre temps sauve des vies.</p>
      </header>

      {/* Étapes : 1, 2, 3 */}
      <section className="guide-steps">
        {/* Étape 1 */}
        <div className="guide-step">
          <div className="step-header">
            <div className="step-icon">
              <FaMicrophone size={24} />
            </div>
            <h2>1. Dictez</h2>
          </div>
          <p>
            Dictez votre <strong>consultation</strong>, <strong>note de suite</strong>, <strong>e-mail</strong>, etc.
            comme à votre secrétaire, sans ponctuation ni mise en page. Clarity s’occupe de tout.
          </p>
        </div>

        {/* Étape 2 */}
        <div className="guide-step">
          <div className="step-header">
            <div className="step-icon">
              <FaFileAlt size={24} />
            </div>
            <h2>2. Recevez</h2>
          </div>
          <p>
            Votre rapport est mis en forme sur notre site <strong>en quelques secondes</strong>. Copiez <strong>tout le rapport</strong> ou <strong>section par section</strong>, elles seront identifiées automatiquement (anamnèse, status, etc.) dans
            votre logiciel médical.
          </p>
        </div>

{/* Étape 3 */}
<div className="guide-step">
  <div className="step-header">
    <div className="step-icon">
      <FaShareAlt size={24} /> {/* Ajouter l'icône de partage */}
    </div>
    <h2>3. Partagez</h2>
  </div>
  <p>
    Partagez facilement votre rapport médical avec vos collègues selon le format de votre choix : e-mail, PDF, Word.
  </p>
</div>
      </section>

              {/* Étape 4 */}
              <div className="guide-step">
          <div className="step-header">
            <div className="step-icon">
              <FaGlobe size={24} />
            </div>
            <h2>4. Améliorez</h2>
          </div>
          <p>
            Corrigez et complétez vos rapports pour <strong>former Clarity à vos habitudes.</strong> Plus vous l’utilisez, plus elle devient précise.
          </p>
        </div>

      {/* Bouton d'appel à l'action */}
      <div className="cta-container">
        <a
          className="cta-button"
          href="https://t.me/ClarityGBM_bot"
          target="_blank"
          rel="noopener noreferrer"
        >
          Essayez Clarity gratuitement
        </a>
      </div>

  
    </div>
  );
}

export default GuideUtilisationPage;