import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Login({ onLoginSuccess }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showForgotPasswordMessage, setShowForgotPasswordMessage] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault(); // Empêche le rechargement de la page lors de la soumission du formulaire
    try {
      const response = await axios.post(`${API_BASE_URL}/login`, {
        name: username,
        password,
      });
      const { chatId } = response.data;
      localStorage.setItem('chatId', chatId);
      onLoginSuccess();
      navigate('/reports');
    } catch (err) {
      setError("Nom d'utilisateur ou mot de passe incorrect");
    }
  };

  const handleForgotPassword = () => {
    setShowForgotPasswordMessage(true);
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <div className="logo-container">
          <img src="logo_carmin.png" alt="Logo" className="logo-carmin" />
        </div>
        <h2 className="app-title">Clarity</h2>
        <h3 className="login-title">Connexion</h3>
        <form onSubmit={handleLogin}> {/* Ajout de la balise <form> */}
          <div className="form-group">
            <label htmlFor="username" className="form-label">
              Nom d'utilisateur
            </label>
            <input
              id="username"
              type="text"
              className="form-input"
              placeholder="Entrez votre nom d'utilisateur"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password" className="form-label">
              Mot de passe
            </label>
            <input
              id="password"
              type="password"
              className="form-input"
              placeholder="Entrez votre mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <Button variant="primary" type="submit">
            Connexion
          </Button>
          {error && <p className="error-message">{error}</p>}
        </form>
        <button className="forgot-password-link" onClick={handleForgotPassword}>
          Mot de passe oublié ?
        </button>
        {showForgotPasswordMessage && (
          <p className="forgot-password-message">
            Obtenez votre mot de passe en faisant <strong>/app</strong> sur notre bot Telegram.
          </p>
        )}
      </div>
    </div>
  );
}

export default Login;