import { Document, Packer, Paragraph, TextRun, AlignmentType } from 'docx';

// 1) Bring in your existing loadKeywordsFromLocalStorage:
function loadKeywordsFromLocalStorage() {
  const storedKeywords = localStorage.getItem('keywords');
  if (!storedKeywords) return [];
  return storedKeywords.split(',').map(word => word.trim());
}

// 2) This small helper to detect if a line is a "title" based on your logic
function isTitleLine(line) {
  const keywords = loadKeywordsFromLocalStorage();
  const trimmedLine = line.trim();
  if (!trimmedLine) return false;

  for (let keyword of keywords) {
    // Must start with keyword, be short enough, etc.
    if (trimmedLine.startsWith(keyword) && trimmedLine.length <= 70) {
      // If there's a colon, check that it ends with that colon
      if (trimmedLine.includes(':') && !trimmedLine.endsWith(':')) {
        continue; // skip this keyword
      }
      // All conditions are OK => It's a title
      return true;
    }
  }
  return false;
}

export const exportToWord = async ({
  userAddress,
  destinationAddress,
  concerneText,
  introductionText,
  locationDate,
  initialSalutation,
  finalSalutation,
  signature,
  reportContent,
  includeUserAddress,
  includeDestinationAddress,
  includeConcerne,
  includeIntroduction,
  includeLocationDate,
  includeInitialSalutation,
  includeFinalSalutation,
  includeSignature
}) => {

  // We'll build the main content paragraphs separately
  let mainContentParagraphs = [];
  if (reportContent) {
    const lines = reportContent.split('\n');

    // Keep track if the previous line was recognized as a title
    let lastLineWasTitle = false;

    mainContentParagraphs = lines.map((line) => {
      const trimmedLine = line.trim();

      // Decide if it's a title only if the previous line was NOT a title
      let isTitle = false;
      if (!lastLineWasTitle && isTitleLine(trimmedLine)) {
        isTitle = true;
      }

      // Construct the paragraph
      const paragraph = new Paragraph({
        children: [
          new TextRun({
            text: trimmedLine,
            bold: isTitle,
            font: 'Arial',
            size: 24
          })
        ],
        alignment: isTitle ? AlignmentType.LEFT : AlignmentType.JUSTIFIED
      });

      // Update for next iteration
      lastLineWasTitle = isTitle;

      return paragraph;
    });
  }

  // Now create the entire doc with all sections
  const doc = new Document({
    sections: [
      {
        children: [
          // Adresse de l'utilisateur (gauche)
          ...(includeUserAddress && userAddress
            ? userAddress.split('\n').map(line =>
                new Paragraph({
                  children: [new TextRun({ text: line, font: 'Arial', size: 24 })],
                  alignment: AlignmentType.LEFT,
                })
              )
            : []
          ),

          ...(includeUserAddress ? [new Paragraph('')] : []),

          // Adresse de destination (droite)
          ...(includeDestinationAddress && destinationAddress
            ? destinationAddress.split('\n').map(line =>
                new Paragraph({
                  children: [new TextRun({ text: line, font: 'Arial', size: 24 })],
                  alignment: AlignmentType.RIGHT,
                })
              )
            : []
          ),

          ...(includeUserAddress || includeDestinationAddress
            ? [new Paragraph(''), new Paragraph(''), new Paragraph('')]
            : []
          ),

          // Lieu et date
          ...(includeLocationDate && locationDate
            ? [
                new Paragraph({
                  children: [new TextRun({ text: locationDate, font: 'Arial', size: 24 })],
                }),
                new Paragraph(''),
                new Paragraph(''),
              ]
            : []
          ),

          // Concerne (en gras)
          ...(includeConcerne && concerneText
            ? [
                new Paragraph({
                  children: [
                    new TextRun({ text: concerneText, bold: true, font: 'Arial', size: 24 }),
                  ],
                }),
                new Paragraph(''),
                new Paragraph(''),
              ]
            : []
          ),

          // Salutation initiale
          ...(includeInitialSalutation && initialSalutation
            ? [
                new Paragraph({
                  children: [new TextRun({ text: initialSalutation, font: 'Arial', size: 24 })],
                }),
                new Paragraph(''),
              ]
            : []
          ),

          // Introduction
          ...(includeIntroduction && introductionText
            ? [
                new Paragraph({
                  children: [new TextRun({ text: introductionText, font: 'Arial', size: 24 })],
                }),
                new Paragraph(''),
                new Paragraph(''),
              ]
            : []
          ),

          // 3) Main report content with consecutive-title-check logic
          ...mainContentParagraphs,

          ...(reportContent ? [new Paragraph(''), new Paragraph(''), new Paragraph('')] : []),

          // Salutation finale
          ...(includeFinalSalutation && finalSalutation
            ? [
                new Paragraph({
                  children: [new TextRun({ text: finalSalutation, font: 'Arial', size: 24 })],
                }),
                new Paragraph(''),
              ]
            : []
          ),

          // Signature (alignée à droite)
          ...(includeSignature && signature
            ? [
                new Paragraph({
                  children: [new TextRun({ text: signature, font: 'Arial', size: 24 })],
                  alignment: AlignmentType.RIGHT,
                  spacing: { before: 400 },
                }),
              ]
            : []
          ),
        ].filter(Boolean).flat(),
      },
    ],
  });

  // Pack and download the .docx
  const blob = await Packer.toBlob(doc);
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'rapport.docx';
  link.click();
};
