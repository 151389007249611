// Header.js
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';

function Header({ handleLogout }) {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  // Ferme le menu burger
  const closeNav = () => {
    setIsNavOpen(false);
  };

  return (
    <header className="app-header">
      <div className="header-left">
        <img src="logo.png" alt="Logo" className="logo" />
        <h1 className="header-title">Clarity</h1>
        {/* Menu de navigation */}
        <nav className={`header-nav ${isNavOpen ? 'open' : ''}`}>
          <NavLink to="/reports" className="nav-link" activeClassName="active" onClick={closeNav}>
            Rapports
          </NavLink>
          <NavLink to="/settings" className="nav-link" activeClassName="active" onClick={closeNav}>
            Réglages
          </NavLink>
          <NavLink to="/guide_complet" className="nav-link" activeClassName="active" onClick={closeNav}>
            Guide
          </NavLink>
          <NavLink to="/download" className="nav-link" activeClassName="active" onClick={closeNav}>
            Téléchargements
          </NavLink>

          {/* Bouton de déconnexion dans le menu burger, visible uniquement sur mobile */}
          <button className="button logout-button nav-link mobile-only" onClick={() => { closeNav(); handleLogout(); }}>
            Se Déconnecter
          </button>
        </nav>
      </div>

      {/* Bouton de déconnexion pour les grands écrans */}
      <div className="header-right desktop-only">
        <button className="button logout-button" onClick={handleLogout}>
          Se Déconnecter
        </button>
      </div>

        {/* Menu burger visible uniquement sur mobile */}
        <div className="burger-menu mobile-only" onClick={toggleNav}>
          ☰
        </div>
    </header>
  );
}

export default Header;