import React from 'react';
import './download.css';

// Quelques icônes depuis react-icons
import { FaMobileAlt, FaDesktop } from 'react-icons/fa';

function TelechargementPage() {
  const handleTestFlightClick = () => {
    window.open(
      'https://apps.apple.com/us/app/testflight/id899247664',
      '_blank',
      'noopener,noreferrer'
    );
  };

  return (
    <div className="telechargement-page">
      {/* Section Clarity pour smartphone */}
      <section className="download-section">
        <div className="section-header">
          <div className="section-icon">
            <FaMobileAlt size={22} />
          </div>
          <h2>Clarity pour smartphone</h2>
        </div>
        <p>
          <strong>
            Installez Clarity sur votre smartphone Android ou iOS pour profiter de : 
          </strong>
        </p>
        <ul>
          <li>Transcription en direct 🎙️</li>
          <li>Scanner de documents 📄</li>
          <li>Fonction brouillon pour la relecture 📝</li>
          <li>Bien d’autres fonctionnalités à découvrir !</li>
        </ul>

        {/* Liens de téléchargement smartphone */}
        <section className="download-links">
          <ul>
            <li>
              <p>
                <strong>Pour Android :</strong> Téléchargez directement l’APK puis installez-le.
              </p>
              <a
                href="https://clarity.gbm-medical.ch/telechargements/Clarity.apk"
                download
              >
                Télécharger Clarity Android 📱
              </a>
            </li>
            <li>
              <p>
                <strong>Pour iPhone : </strong>
                Installez d’abord&nbsp;
                <span
                  className="testflight-link"
                  role="link"
                  onClick={handleTestFlightClick}
                >
                  TestFlight
                </span>
                &nbsp;depuis l’App Store, puis utilisez le lien ci-dessous.
              </p>
              <a
                href="https://testflight.apple.com/join/4cWZWkm8"
                download
              >
                Télécharger Clarity iPhone 📱
              </a>
            </li>
          </ul>
        </section>
      </section>

      {/* Section Clarity Desktop */}
      <section className="download-section">
        <div className="section-header">
          <div className="section-icon">
            <FaDesktop size={22} />
          </div>
          <h2>Clarity pour ordinateur</h2>
        </div>
        <p><strong>Profitez des fonctionnalités avancées sur votre ordinateur :</strong></p>
        <ul>
          <li>
            <strong>Utilisation sans installation sur les ordinateurs hospitaliers</strong> 🏥
          </li>
          <li>Raccourcis clavier pour une insertion rapide des rapports 🚀</li>
          <li>Stockage local illimité 📂</li>
        </ul>

        {/* Liens de téléchargement desktop */}
        <section className="download-links">
          <ul>
            <li>
              <li>
                <strong>Pour Windows :</strong> version sans installation.
              </li>
              <a
                href="https://clarity.gbm-medical.ch/telechargements/Clarity.exe"
                download
              >
                Télécharger Clarity pour Windows 💻
              </a>
            </li>
            <li>
              <li>
                <strong>Pour MacOS :</strong> version compatible.
              </li>
              <a
                href="https://clarity.gbm-medical.ch/telechargements/Clarity.dmg"
                download
              >
                Télécharger Clarity pour MacOS 💻
              </a>
            </li>
          </ul>
        </section>
      </section>
    </div>
  );
}

export default TelechargementPage;