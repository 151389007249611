import React, { useState } from 'react';
import './guide_complet.css';

// Import des icônes depuis react-icons
import {
  FaMicrophone,
  FaFileAlt,
  FaGlobe,
  FaClipboardList,
  FaLanguage,
  FaShareAlt,
  FaCamera,
  FaPlane,
  FaShieldAlt
} from 'react-icons/fa';

function GuideUtilisationPage() {
  // État local pour afficher/masquer la liste des langues
  const [showLanguages, setShowLanguages] = useState(false);

  // Fonction de bascule pour le bouton “Voir / Cacher”
  const toggleLanguages = () => {
    setShowLanguages((prev) => !prev);
  };

  return (
    <div className="guide-page">
      {/* En-tête */}
      <header className="guide-header">
        <h1>Comment ça marche&nbsp;?</h1>
        <p>Clarity sauve votre temps. Votre temps sauve des vies.</p>
      </header>

      {/* Étapes : 1, 2, 3, 4 */}
      <section className="guide-steps">
        {/* Étape 1 */}
        <div className="guide-step">
          <div className="step-header">
            <div className="step-icon">
              <FaMicrophone size={24} />
            </div>
            <h2>1. Dictez</h2>
          </div>
          <p>
            Dictez votre <strong>consultation</strong>, <strong>note de suite</strong>, 
            <strong> lettre</strong>, etc. comme à votre secrétaire, sans ponctuation ni mise en page. 
            Clarity s’occupe de tout.
          </p>
        </div>

        {/* Étape 2 */}
        <div className="guide-step">
          <div className="step-header">
            <div className="step-icon">
              <FaFileAlt size={24} />
            </div>
            <h2>2. Recevez</h2>
          </div>
          <p>
            Votre rapport est mis en forme <strong>en quelques secondes</strong>.  Copiez <strong>tout le rapport</strong> ou <strong>section par section</strong> (anamnèse, status, etc.) depuis notre site dans votre logiciel médical, ou partagez le en PDF, Word ou par e-mail.
          </p>
        </div>

                {/* Étape 4 */}
                <div className="guide-step">
          <div className="step-header">
            <div className="step-icon">
              <FaGlobe size={24} />
            </div>
            <h2>3. Améliorez</h2>
          </div>
          <p>
            Corrigez et complétez vos rapports pour <strong>former Clarity</strong> à vos habitudes. Plus vous l’utilisez, plus Clarity devient précis.
          </p>
        </div>
      </section>


      {/* Bouton d'appel à l'action */}
      <div className="cta-container">
        <a
          className="cta-button"
          href="https://t.me/ClarityGBM_bot"
          target="_blank"
          rel="noopener noreferrer"
        >
          Essayez Clarity gratuitement
        </a>
      </div>

      {/* Section "Types de rapport" */}
      <section className="report-types">
        <div className="section-header">
          <div className="section-icon">
            <FaClipboardList size={24} />
          </div>
          <h2>Types de rapport</h2>
        </div>
        <p>
          Clarity permet de <strong>générer plusieurs types de rapports médicaux</strong> selon des schémas pré-conçus : consultations, lettre, note de suite, e-mail, rapports pour assurances, etc. Sélectionnez-le manuellement ou précisez-le en début de dictée (par ex. "Je dicte une consultation...").
        </p>
        <p>
          Selon le type de rapport choisi, il existe une structure de lettre pré-établie, que vous pouvez ensuite personnaliser. <strong>Les sections-clés</strong> (ex. anamnèse, status, médicaments) sont identifiées automatiquement et vos rapports sont ainsi <strong>clairs, structurés et facilement transmissibles</strong>.
        </p>
      </section>

       {/* Section AddScan */}
       <section className="report-types">
        <div className="section-header">
          <div className="section-icon">
            <FaCamera size={24} />
          </div>
          <h2>AddScan</h2>
        </div>
        <p>
          Clarity propose la fonction <strong>AddScan</strong>, qui vous permet de prendre des photos de documents existants (listes de médicaments, diagnostics, etc.) et de les <strong>intégrer directement</strong> dans votre rapport en cours de création.
        </p>
        <p>
          Par exemple, pour un patient transféré depuis un autre hôpital : prenez en photo sa lettre de sortie dans une autre langue, Clarity <strong>analyse et traduit</strong> automatiquement les diagnostics et traitements, et les incorpore à votre nouveau rapport.
        </p>
      </section>

      {/* Section "Traduction" (anciennement Langues disponibles) */}
      <section className="languages">
        <div className="section-header">
          <div className="section-icon">
            <FaLanguage size={24} />
          </div>
          <h2>Traduction</h2>
        </div>
        <p>
          Clarity vous permet de dicter et de recevoir vos rapports dans un large choix de <strong>langues</strong>. 
          Par exemple, parlez en français et obtenez le texte en allemand, ou l’inverse. Les <strong>dialectes 
          suisses-allemands</strong> sont également pris en charge.
        </p>
        <p>
          Clarity peut aussi <strong>enregistrer la voix d’un patient</strong> ne parlant pas votre langue, 
          et <strong>traduire en direct</strong> l’anamnèse recueillie dans la langue de votre choix.  
          <strong>Plus de 50 langues sont disponibles</strong>.
        </p>

        {/* Bouton de bascule pour afficher/cacher la liste complète */}
        <button className="toggle-button" onClick={toggleLanguages}>
          {showLanguages ? 'Cacher la liste complète' : 'Voir la liste complète'}
        </button>

        {/* Liste des langues conditionnellement affichée */}
        {showLanguages && (
          <div className="language-grid">
            <span>🇿🇦 Afrikaans</span>
            <span>🇩🇪 Allemand</span>
            <span>🇬🇧 Anglais</span>
            <span>🇸🇦 Arabe</span>
            <span>🇦🇲 Arménien</span>
            <span>🇦🇿 Azéri</span>
            <span>🇧🇾 Biélorusse</span>
            <span>🇧🇦 Bosniaque</span>
            <span>🇧🇬 Bulgare</span>
            <span>🇪🇸 Catalan</span>
            <span>🇨🇳 Chinois</span>
            <span>🇰🇷 Coréen</span>
            <span>🇭🇷 Croate</span>
            <span>🇩🇰 Danois</span>
            <span>🇪🇸 Espagnol</span>
            <span>🇪🇪 Estonien</span>
            <span>🇫🇮 Finnois</span>
            <span>🇫🇷 Français</span>
            <span>🇪🇸 Galicien</span>
            <span>🏴 Gallois</span>
            <span>🇬🇷 Grec</span>
            <span>🇮🇳 Hindi</span>
            <span>🇭🇺 Hongrois</span>
            <span>🇮🇱 Hébreu</span>
            <span>🇮🇩 Indonésien</span>
            <span>🇮🇸 Islandais</span>
            <span>🇮🇹 Italien</span>
            <span>🇯🇵 Japonais</span>
            <span>🇮🇳 Kannada</span>
            <span>🇰🇿 Kazakh</span>
            <span>🇱🇻 Letton</span>
            <span>🇱🇹 Lituanien</span>
            <span>🇲🇰 Macédonien</span>
            <span>🇲🇾 Malais</span>
            <span>🇳🇿 Maori</span>
            <span>🇮🇳 Marathi</span>
            <span>🇳🇴 Norvégien</span>
            <span>🇳🇱 Néerlandais</span>
            <span>🇳🇵 Népalais</span>
            <span>🇵🇰 Ourdou</span>
            <span>🇮🇷 Persan</span>
            <span>🇵🇱 Polonais</span>
            <span>🇵🇹 Portugais</span>
            <span>🇷🇴 Roumain</span>
            <span>🇷🇺 Russe</span>
            <span>🇷🇸 Serbe</span>
            <span>🇸🇰 Slovaque</span>
            <span>🇸🇮 Slovène</span>
            <span>🇸🇪 Suédois</span>
            <span>🇰🇪 Swahili</span>
            <span>🇵🇭 Tagalog</span>
            <span>🇮🇳 Tamoul</span>
            <span>🇨🇿 Tchèque</span>
            <span>🇹🇭 Thaï</span>
            <span>🇹🇷 Turc</span>
            <span>🇺🇦 Ukrainien</span>
            <span>🇻🇳 Vietnamien</span>
          </div>
        )}
      </section>


      {/* Section Mode Avion */}
      <section className="report-types">
        <div className="section-header">
          <div className="section-icon">
            <FaPlane size={24} />
          </div>
          <h2>Mode avion</h2>
        </div>
        <p>
          En cas de connexion internet instable, Clarity enregistre votre dictée hors-ligne. Dès que la connexion est rétablie, il génère automatiquement le rapport. Vous pouvez ainsi <strong>dicter où que vous soyez</strong>.
        </p>
      </section>

      {/* Section Sécurité des données */}
      <section className="report-types">
        <div className="section-header">
          <div className="section-icon">
            <FaShieldAlt size={24} />
          </div>
          <h2>Sécurité des données</h2>
        </div>
        <p>
          Clarity s’appuie sur plusieurs <strong>IA spécialisées</strong> dans la rédaction de rapports médicaux. Étant donné la sensibilité des données, elles sont exclusivement <strong>stockées sur des serveurs suisses sécurisés</strong>.
        </p>
        <p>
         Personne n'a accès à ces serveurs : vos données restent ainsi <strong>confidentielles et protégées</strong>.
        </p>
      </section>
    </div>
  );
}

export default GuideUtilisationPage;