// Settings.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Settings.css';
import Button from './Button';
import LanguagesList from './LanguagesList';
import {
  FaUser,
  FaListAlt,
  FaLanguage,
  FaKeyboard,
  FaTags,
  FaChevronLeft,
  FaSave,
} from 'react-icons/fa';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Settings() {
  const [username, setUsername] = useState('Guest');
  const [email, setEmail] = useState('');
  const [emailToggle, setEmailToggle] = useState(false);
  const [vocabulary, setVocabulary] = useState([]);
  const [newVocab, setNewVocab] = useState('');
  const [selectedVocabulary, setSelectedVocabulary] = useState('');
  const [reportLanguage, setReportLanguage] = useState('français');
  const [dictationLanguage, setDictationLanguage] = useState('fr');
  const [hasChanges, setHasChanges] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [showKeywords, setShowKeywords] = useState(false);
  const [newKeyword, setNewKeyword] = useState('');

  const isRefreshingRef = useRef(false);

  // Exemple de raccourcis
  const shortcuts = [
    { key: 'Ctrl + 1', action: 'Copier la première section' },
    { key: 'Ctrl + 2', action: 'Copier la deuxième section' },
    { key: 'Ctrl + Shift + C', action: 'Copier le rapport entier' },
    { key: 'Ctrl + Shift + S', action: 'Améliorer votre IA avec le rapport' },
    { key: 'Ctrl + Shift + R', action: 'Rafraîchir les rapports' },
    { key: 'Ctrl + Shift + Z', action: 'Annuler les modifications' }
  ];

  // Ajouter / supprimer un mot-clé
  const handleAddKeyword = () => {
    if (newKeyword && !keywords.includes(newKeyword.trim())) {
      const updatedKeywords = [...keywords, newKeyword.trim()];
      setKeywords(updatedKeywords);
      setNewKeyword('');
      setHasChanges(true);
      localStorage.setItem('keywords', updatedKeywords.join(', '));
    }
  };

  const handleRemoveKeyword = (keywordToRemove) => {
    const updatedKeywords = keywords.filter((kw) => kw !== keywordToRemove);
    setKeywords(updatedKeywords);
    setHasChanges(true);
    localStorage.setItem('keywords', updatedKeywords.join(', '));
  };

  // Annuler modifications
  const handleCancel = () => {
    window.location.reload();
  };

  // Charger les réglages existants
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const chatId = localStorage.getItem('chatId');
        const response = await axios.post(`${API_BASE_URL}/getSettings`, { chatId });
        const data = response.data;

        setUsername(data.name || 'Guest');
        setEmail(data.email || '');
        setEmailToggle(Boolean(data.emailenabled));
        setVocabulary(
          data.voc
            ? data.voc.split(',').map((word) => word.trim()).filter((word) => word)
            : []
        );
        setReportLanguage(data.langue || 'français');
        setDictationLanguage(data.french || 'fr');
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };

    fetchSettings();

    // Charger les mots-clés depuis localStorage
    const storedKeywords = localStorage.getItem('keywords');
    if (storedKeywords) {
      setKeywords(storedKeywords.split(',').map((word) => word.trim()));
    }
  }, []);

  // Sauvegarder
  const handleSave = async () => {
    try {
      const chatId = localStorage.getItem('chatId');
      const vocabularyString = vocabulary.join(', ');
      const keywordsString = keywords.join(', ');

      const response = await axios.post(`${API_BASE_URL}/updateSettings`, {
        chatId,
        email,
        emailenabled: emailToggle,
        vocabulary: vocabularyString,
        french: dictationLanguage,
        langue: reportLanguage,
      });

      if (response.status === 200) {
        alert('Réglages sauvegardés avec succès');
        setHasChanges(false);
        localStorage.setItem('keywords', keywordsString);
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde des réglages:', error);
      alert('Erreur lors de la sauvegarde des réglages');
    }
  };

  // Handlers génériques
  const handleToggleChange = (setter) => (e) => {
    setter(e.target.checked);
    setHasChanges(true);
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setHasChanges(true);
  };

  // Vocabulaire
  const handleAddVocabulary = () => {
    if (newVocab && !vocabulary.includes(newVocab.trim())) {
      setVocabulary([...vocabulary, newVocab.trim()]);
      setNewVocab('');
      setHasChanges(true);
    }
  };

  const handleRemoveVocabulary = () => {
    const updatedVocabulary = vocabulary.filter((vocab) => vocab !== selectedVocabulary);
    setVocabulary(updatedVocabulary);
    setSelectedVocabulary('');
    setHasChanges(true);
  };

  return (
    <div className="settings-container">
      {/* SECTION COMPTE */}
      <section>
        <h2>
          <span className="icon-section">
            <FaUser />
          </span>
          Compte
        </h2>
        <p>Utilisateur : {username}</p>
        <div className="form-group">
          <label htmlFor="email" className="form-label">
            E-mail :
          </label>
          <input
            id="email"
            type="email"
            className="form-input"
            value={email}
            onChange={handleInputChange(setEmail)}
            placeholder="Entrez votre e-mail"
          />
          <label className="toggle-label">
            Recevoir les rapports par e-mail :
            <input
              type="checkbox"
              checked={emailToggle}
              onChange={handleToggleChange(setEmailToggle)}
            />
          </label>
        </div>
      </section>

      {/* SECTION VOCABULAIRE */}
      <section>
        <h2>
          <span className="icon-section">
            <FaListAlt />
          </span>
          Vocabulaire
        </h2>
        <p>
          📄 Liste de vocabulaire :{' '}
          {vocabulary.length > 0 ? vocabulary.join(', ') : 'Aucun mot pour le moment'}
        </p>
        <div className="vocabulary-input">
          <input
            type="text"
            placeholder="Ajouter du vocabulaire..."
            value={newVocab}
            onChange={(e) => setNewVocab(e.target.value)}
          />
          <Button variant="success" onClick={handleAddVocabulary} disabled={!newVocab.trim()}>
            Ajouter
          </Button>
        </div>
        <div className="vocabulary-controls">
          <select
            value={selectedVocabulary}
            onChange={handleInputChange(setSelectedVocabulary)}
          >
            <option value="">Choisir un mot à supprimer</option>
            {vocabulary.map((vocab) => (
              <option key={vocab} value={vocab}>
                {vocab}
              </option>
            ))}
          </select>
          <Button variant="danger" disabled={!selectedVocabulary} onClick={handleRemoveVocabulary}>
            Supprimer
          </Button>
        </div>
      </section>

      {/* SECTION LANGUE */}
      <section>
        <h2>
          <span className="icon-section">
            <FaLanguage />
          </span>
          Langue
        </h2>
        <label className="input-label">
          📄 Langue des rapports :
          <select value={reportLanguage} onChange={handleInputChange(setReportLanguage)}>
            {Object.keys(LanguagesList.languages).map((lang) => (
              <option key={lang} value={LanguagesList.languages[lang]}>
                {lang}
              </option>
            ))}
          </select>
        </label>
        <label className="input-label">
          🎙️ Langue de dictée :
          <select value={dictationLanguage} onChange={handleInputChange(setDictationLanguage)}>
            {Object.keys(LanguagesList.languages).map((lang) => (
              <option key={lang} value={LanguagesList.languages[lang]}>
                {lang}
              </option>
            ))}
          </select>
        </label>
      </section>

      {/* SECTION RACCOURCIS CLAVIER */}
      <section>
        <h2>
          <span className="icon-section">
            <FaKeyboard />
          </span>
          Raccourcis clavier
        </h2>
        <ul className="shortcut-list">
          {shortcuts.map((shortcut, index) => (
            <li key={index}>
              <span className="key-icon">{shortcut.key}</span> : {shortcut.action}
            </li>
          ))}
        </ul>
      </section>

      {/* SECTION MOTS-CLÉS */}
      <section>
        <h2>
          <span className="icon-section">
            <FaTags />
          </span>
          Mots-clés
        </h2>
       
        <p>
          Plusieurs mots-clés déjà enregistrés servent à créer de nouvelles sections lors de la dictée.
          </p>
          <p> Ajoutez-en ci-dessous pour
          personnaliser la structure de vos rapports.
        </p>
        <div className="add-keyword-section">
          <input
            type="text"
            placeholder="Ajouter un mot-clé..."
            value={newKeyword}
            onChange={(e) => setNewKeyword(e.target.value)}
          />
          <Button variant="success" onClick={handleAddKeyword} disabled={!newKeyword.trim()}>
            Ajouter
          </Button>
        </div>
        <Button variant="primary" onClick={() => setShowKeywords((prev) => !prev)}>
          {showKeywords ? 'Masquer les mots-clés' : 'Afficher les mots-clés'}
        </Button>
        {showKeywords && (
          <div className="keywords-section">
            <h4>Liste des mots-clés</h4>
            <ul>
              {keywords.length === 0 && <p>Aucun mot-clé pour l’instant.</p>}
              {keywords.map((keyword) => (
                <li key={keyword}>
                  {keyword}
                  <Button variant="danger" onClick={() => handleRemoveKeyword(keyword)}>
                    Supprimer
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </section>

      {/* Barre fixe du bouton de sauvegarde */}
      <div className="save-button-bar">
        <Button variant="secondary" disabled={!hasChanges} onClick={handleCancel}>
          <FaChevronLeft /> Retour
        </Button>
        <Button variant="primary" disabled={!hasChanges} onClick={handleSave}>
          <FaSave /> Sauvegarder les réglages
        </Button>
      </div>
    </div>
  );
}

export default Settings;