// App.js
import React, { useEffect, useState } from 'react';
import {
  HashRouter as Router,  // Switch from BrowserRouter to HashRouter
  Routes,
  Route,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import Login from './Login';
import ReportList from './ReportList';
import Settings from './Settings';
import Download from './download';
import Guide from './guide';
import GuideComplet from './guide_complet';
import Header from './Header';
import './App.css'; // Import the global CSS file

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const isElectron = typeof window !== 'undefined' && window.isElectron;
  const navigate = useNavigate();

  useEffect(() => {
    const chatId = localStorage.getItem('chatId');
    const isUserAuthenticated = !!chatId;
    setIsAuthenticated(isUserAuthenticated);
  
    if (isElectron) {
      if (isUserAuthenticated) {
        window.electronAPI.userAuthenticated();
      }
  
      const handleLogoutEvent = () => {
        console.log('Logout event received in App.js');
        localStorage.removeItem('chatId');
        localStorage.removeItem('lastRefreshDate');
        localStorage.removeItem('reports');
        setIsAuthenticated(false);
        navigate('/login');
      };
  
      window.electronAPI.on('logout', handleLogoutEvent);
  
      window.electronAPI.on('navigate', (route) => {
        if (localStorage.getItem('chatId')) {
          navigate(`/${route}`);
        } else {
          navigate('/login');
        }
      });
  
      return () => {
        if (isElectron) {
          window.electronAPI.off('navigate', navigate);
          window.electronAPI.off('logout', () => setIsAuthenticated(false));
        }
      };
    }
  }, [isAuthenticated, navigate, isElectron]);
  
  

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  
    // Informer le processus principal que l'utilisateur est authentifié
    if (window.electronAPI) {
      window.electronAPI.userAuthenticated();
    }
  };
  
  const handleLogout = () => {
    localStorage.clear();
    setIsAuthenticated(false);
  
    // Informer le processus principal que l'utilisateur s'est déconnecté
    if (window.electronAPI) {
      window.electronAPI.userLoggedOut();
    }
  };  
  
  return (
    <div className={`app ${isElectron ? 'electron-mode' : 'web-mode'}`}>
      {/* Affichez l'en-tête seulement en mode Web */}
      {!isElectron && isAuthenticated && <Header handleLogout={handleLogout} />}
      <Routes>
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/reports" /> : <Login onLoginSuccess={handleLoginSuccess} />}
        />
       <Route
  path="/reports"
  element={
    isAuthenticated ? (
      <ReportList isElectron={isElectron} />
    ) : (
      <Navigate to="/login" />
    )
  }
/>
        <Route
          path="/settings"
          element={isAuthenticated ? <Settings /> : <Navigate to="/login" />}
        />
        <Route
          path="/download"
          element={isAuthenticated ? <Download /> : <Navigate to="/login" />}
        />
        <Route
          path="/guide"
          element={<Guide /> }
        />
        <Route
          path="/guide_complet"
          element={<GuideComplet /> }
        />
        
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
}


export default App;